import React from "react";
import { FaFacebook, FaInstagram, FaEnvelope, FaPhone, FaYoutube } from "react-icons/fa";

function Strip() {
  return (
    <div className="flex justify-between bg-black w-full p-2 container sm:hidden">
      <div className="flex justify-between ">
        <a
          href="mailto:ritik@mlsclasses.com"
          className="flex items-center text-white ms-2"
        >
          <FaEnvelope className="me-2 text-golden" /> ritik@mlsclasses.com
        </a>
        <a
          href="tel:+919649549754"
          className="flex items-center ms-2 text-white"
        >
          <FaPhone className="me-2 text-golden" /> (+91)9649549754
        </a>
      </div>
      <div className="flex justify-between">
        <a
          href="https://wa.me/message/XMS5KMWBGQZLG1"
          target="_blank"
          className="flex items-center ms-2"
          rel="noreferrer"
        >
          <span className="text-golden underline">WhatsApp</span>
        </a>
        <a
          href="https://www.facebook.com/mlsclasses?mibextid=ZbWKwL"
          target="_blank"
          className="flex items-center text-xl ms-2 text-white"
          rel="noreferrer"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.youtube.com/@mlsclasses8293?si=KBojcjPosvKjfwjH"
          target="_blank"
          className="flex items-center text-xl ms-2 text-white"
          rel="noreferrer"
        >
          <FaYoutube />
        </a>
        <a
          href="https://www.instagram.com/mlsclasses?utm_source=ig_web_button_share_sheet"
          target="_blank"
          className="flex items-center text-xl ms-2 text-white"
          rel="noreferrer"
        >
          <FaInstagram />
        </a>
      </div>
    </div>
  );
}

export default Strip;
