import React from "react";
import { FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { RiFacebookFill } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";

const Icons = ({ icon, href }) => {
  return (
    <div className="w-10 flex justify-center items-center text-2xl h-10 bg-[#5295b3] rounded-full">
      <a href={href} target="_blank" rel="noreferrer">
        <p className="text-yellow-400">{icon} </p>
      </a>
    </div>
  );
};

export default function AppFooter() {
  return (
    <div className="">
      <div className="flex flex-wrap py-10 bg-[#151414] justify-evenly sm:flex-col sm:items-left sm:px-10 sm:flex-nowrap">
        <div className="flex flex-col sm:items-left mb-2">
          <a href="/" className="">
            <img
              src={"/static/logo.webp"}
              alt="mls-logo"
              className="h-28 w-52"
            />
          </a>
          <div className="w-[23rem] sm:w-full text-justify text-base text-[#D5D5D5]">
            MLS Classes offers Live, Online 1-1 Personalized Tutoring of Math,
            English(ELA), Science, Coding And SAT, ACT, AP, Digital SSAT, STAAR,
            AMC, GCSE, A-level, IGCSE, IB and NAPLAN Classes for students in
            grades K-12
          </div>
        </div>

        <div className="flex items-start flex-col sm:items-left mt-16 md:mt-0 my-2 gap-2">
          <h2 className="text-lg font-bold">Company</h2>
          <div>
            <a href="/about-us" className="text-[#D5D5D5] flex items-center">
              <IoIosArrowForward className="mr-2" />
              About Us
            </a>
          </div>
          <div>
            <a href="/sitemap.html" className="text-[#D5D5D5] flex items-center">
              <IoIosArrowForward className="mr-2" />
              Sitemap
            </a>
          </div>
        </div>
        <div className="flex items-start flex-col sm:items-left mt-16 md:mt-0 my-2 gap-2">
          <h2 className="text-lg font-bold text-white">Reach us</h2>
          <div>
            <a href="mailto:ritik@mlsclasses.com" className="text-[#D5D5D5] flex items-center">
              <IoIosArrowForward className="mr-2" />
              ritik@mlsclasses.com
            </a>
          </div>
          <div>
            <a href="/contact-us" className="text-[#D5D5D5] flex items-center">
              <IoIosArrowForward className="mr-2" />
              Contact us
            </a>
          </div>
        </div>

        <div className="flex items-start flex-col sm:items-left mt-16 md:mt-0 my-2 gap-2">
          <div className="text-lg font-bold text-white">Other Resources</div>
          <div>
            <a href="/blogs" className="text-[#D5D5D5] flex items-center">
              <IoIosArrowForward className="mr-2" />
              Blogs
            </a>
          </div>
          <div>
            <a href="/resource-center" className="text-[#D5D5D5] flex items-center">
              <IoIosArrowForward className="mr-2" />
              Resources Center
            </a>
          </div>
          <div>
            <a href="/affiliats" className="text-[#D5D5D5] flex items-center">
              <IoIosArrowForward className="mr-2" />
              Affiliates
            </a>
          </div>
        </div>

        <div className="flex flex-col mt-10 lg:mt-0">
          <div className="text-2xl font-semibold text-start my-6">
            Follow us
          </div>
          <div className="flex gap-3">
            <Icons
              icon={<RiFacebookFill />}
              href={"https://www.facebook.com/mlsclasses?mibextid=ZbWKwL"}
            />
            <Icons
              icon={<FaInstagram />}
              href={
                "https://www.instagram.com/mlsclasses?utm_source=ig_web_button_share_sheet"
              }
            />
            <Icons
              icon={<FaLinkedinIn />}
              href={"https://www.linkedin.com/company/online-tutor-usa/"}
            />
            <Icons
              icon={<FaYoutube />}
              href={
                "https://www.youtube.com/@mlsclasses8293?si=KBojcjPosvKjfwjH"
              }
            />
            <Icons
              icon={<FaWhatsapp />}
              href={"https://wa.me/message/XMS5KMWBGQZLG1"}
            />
          </div>
        </div>
      </div>
      <div className="w-100 bg-[#202020]  flex flex-col justify-between items-center py-6 gap-3 uppercase text-sm sm:text-xs">
        <div>
          <ul className="flex sm:my-2 gap-5">
            <li className="ms-2">
              <a href="/terms" className="text-[#D5D5D5]">
                Terms &amp; Conditions
              </a>
            </li>
            <li className="ms-2">
              <a href="/privacy-policy" className="text-[#D5D5D5]">
                Privacy Policy
              </a>
            </li>
            <li className="ms-2">
              <a href="/cancel" className="text-[#D5D5D5]">
                Cancellation & Refund
              </a>
            </li>
          </ul>
        </div>
        <div class=" sm:my-2 w-full">
          <span className=" text-[#878787] w-full">
            Copyright © 2024 All Rights Reserved
            <a
              href="https://www.mlsclasses.com/"
              target="_blank"
              rel="noreferrer"
              className="text-white ms-2"
            >
              mlsclasses.com
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

// MLS Classes: Interactive 1:1 Online Classes: Digital SAT, ACT, AP,
// Common Core, IB & IGCSE.
