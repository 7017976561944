import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LayoutTopBottom from "./layout/LayoutTopBottom";
import ScrollToTop from "./layout/ScrollToTop";
import Loader from "./components/loader";

const PublicRoutes = lazy(() => import('./routes/PublicRoutes'));
const AdminRoutes = lazy(() => import('./routes/AdminRoutes'));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/*"
            element={
              <LayoutTopBottom>
                <PublicRoutes />
              </LayoutTopBottom>
            }
          />
          <Route path="/admin/*" element={<AdminRoutes />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
